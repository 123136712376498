var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "page-support-email"
  }, [_c('v-btn', {
    staticClass: "text-none",
    class: !_vm.displayButton ? 'd-none' : '',
    attrs: {
      "color": "info"
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.dialogSupportEmail = true;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v("fas fa-headset")]), !_vm.btnText ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('Support')) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.btnText) + " ")])], 1), _c('v-dialog', {
    staticStyle: {
      "z-index": "1000"
    },
    attrs: {
      "primary-title": "",
      "max-width": "600",
      "persistent": ""
    },
    model: {
      value: _vm.dialogSupportEmail,
      callback: function ($$v) {
        _vm.dialogSupportEmail = $$v;
      },
      expression: "dialogSupportEmail"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "headline"
  }, [_vm._v(" " + _vm._s(`${_vm.preSubject} ${_vm.subjectMessage}`) + " ")]), _c('v-divider', {
    staticClass: "mt-1"
  }), _c('div', {
    staticClass: "col-12"
  }, [_c('v-card', {
    staticClass: "pa-4",
    attrs: {
      "elevation": "0"
    }
  }, [_vm.displayInformation ? _c('span', [_vm._v(_vm._s(_vm.$t('supportMessage')))]) : _vm._e(), _c('v-form', {
    ref: "supportEmailForm",
    staticClass: "mt-6"
  }, [_c('v-text-field', {
    attrs: {
      "type": "email",
      "label": _vm.$t('Email'),
      "disabled": "",
      "filled": "",
      "value": _vm.$appConfig.user.email
    }
  }), _vm.displaySubject ? _c('v-text-field', {
    attrs: {
      "label": _vm.$t('Subject'),
      "required": "",
      "outlined": "",
      "rules": _vm.subjectRules
    },
    model: {
      value: _vm.subjectMessage,
      callback: function ($$v) {
        _vm.subjectMessage = $$v;
      },
      expression: "subjectMessage"
    }
  }) : _vm._e(), _c('v-textarea', {
    attrs: {
      "label": _vm.$t('Message'),
      "required": "",
      "outlined": "",
      "auto-grow": true,
      "rules": _vm.messageRules
    },
    model: {
      value: _vm.bodyMessage,
      callback: function ($$v) {
        _vm.bodyMessage = $$v;
      },
      expression: "bodyMessage"
    }
  })], 1)], 1)], 1), _c('v-divider', {
    staticClass: "mb-1"
  }), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "error"
    },
    on: {
      "click": function ($event) {
        _vm.dialogSupportEmail = false;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v("fas fa-ban")]), _vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")], 1), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "type": "submit",
      "color": "info",
      "disabled": _vm.loading,
      "loading": _vm.loading
    },
    on: {
      "click": _vm.sendTicket
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v("far fa-paper-plane")]), _vm._v(" " + _vm._s(_vm.$t("Send")) + " ")], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }