<template lang="html" src="./supportEmail.template.vue"></template>

<style lang="scss" src="./supportEmail.scss"></style>

<script>
import { ApiErrorParser } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./supportEmail.i18n');

export default {
  name: 'SupportEmail',
  props: {
    defaultSubject: {
      type: String,
      default: '',
    },
    displayButton: {
      type: Boolean,
      default: true,
    },
    displayInformation: {
      type: Boolean,
      default: true,
    },
    displaySubject: {
      type: Boolean,
      default: true,
    },
    hiddenPreSubject: {
      type: String,
      default: '',
    },
    preSubject: {
      type: String,
      default: '',
    },
    btnText: {
      type: String,
      default: null
    },
    defaultText: {
      type: String,
      default: null
    }
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      /**
       * Main variable
       */
      bodyMessage: '',
      subjectMessage: '',
      /**
       * Boolean logic
       */
      dialogSupportEmail: false,
      loading: false,
      /**
       * Rules
       */
      subjectRules: [(value) => !!value || this.$t('ProvideSubject')],
      messageRules: [(value) => !!value || this.$t('ProvideMessage')],
    };
  },
  created() {
    if (this.defaultSubject.trim() !== '') {
      this.subjectMessage = this.defaultSubject;
    }
    if(this.defaultText) {
      this.bodyMessage = this.defaultText;
    }
  },
  methods: {
    open() {
      this.dialogSupportEmail = true;
    },
    sendTicket() {
      if (this.subjectMessage.trim() === '') {
        this.subjectMessage = '';
      }
      if (this.bodyMessage.trim() === '') {
        this.bodyMessage = '';
      }
      if (this.$refs.supportEmailForm.validate()) {
        this.loading = true;
        const sendSupportEmailTicketBody = new this.$BcmModel.SendSupportEmailTicketBody(
          `${this.hiddenPreSubject} ${this.preSubject} ${this.subjectMessage}`,
          this.bodyMessage
        );
        this.$apiInstance
          .sendSupportEmailTicket(sendSupportEmailTicketBody)
          .then(
            () => {
              /**
               * Component BeelseNotifications used
               */
              this.$notification.notify('SUCCESS',this.$t('TicketSent'));
              this.dialogSupportEmail = false;
              this.subjectMessage = '';
              this.bodyMessage = '';
            },
            (error) => {
              /**
               * ERROR MODIFY BRAND PART
               */
              this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));
            }
          )
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
